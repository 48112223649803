<template>
  <div>
    <div class="pcms">
      <div class="h1">{{ $t('content.savorFood') }}</div>
      <div class="box">
        <div
          class="imgbox"
          v-for="(item, index) in pinchangmeishi"
          :key="index"
          :style="{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }"
          v-lazy:background-image="{src: baseUrl + item.imgUrl}"
          @click="pincmeishi(item.id)"
        >
         <div class="title">{{item.title}}</div></div>
      </div>
      <div class="btn" @click="specialty">{{ $t('content.browseMore') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pinchangmeishimes: "品尝美食",
      pinchangmeishi: [],
      liulangengduo: "浏览更多",
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      language: this.$i18n.locale
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    pincmeishi(id) {
      // this.$router.push({
      //   path: "/publicdqmjdetails",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/travelStoreApi/getDetail/'+id
        },
      });
    },
    async home() {
      let result = await this.$http({
        url: `/api/travelStoreApi?cxfl=8&classify=39&size=6&language=${this.language}`,
        method: "get",
      });
      console.log(result);
      this.pinchangmeishi = result.data.data;
      console.log(this.pinchangmeishi);
    },
    specialty() {
      this.$router.push('/public').catch(()=>{})
    }   
  },
};
</script>

<style>
.pcms {
  width: 1200px;
  height: 840px;
  /* background: olivedrab; */
  margin: 0px auto;
  overflow: hidden;
}
.pcms .box {
  width: 1200px;
  height: 560px;
  /* background: red; */
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.pcms .box .imgbox {
  width: 366px;
  height: 272px;
  background: blue;
  margin-right: -30px;
  margin-top: 7px;
  position: relative;
   cursor: pointer;
}
.pcms .box .imgbox .title{
 color: #fff;
 position: absolute;
 bottom: 10px;
 left: 10px;
 font-size: 22px;
}
.pcms .h1 {
  /* width: 211px; */
  height: 48px;
  font-size: 51px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 51px;
  margin: 63px auto 88px;
  text-align: center;
}
/* .pcms .imgbox {
  width: 1200px;
  height: 272px;
  background: orange;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */
/* .pcms .imgbox .imgleft {
  width: 446px;
  height: 560px;
  background: #000000;
  border: 7px solid rgba(244, 176, 18, 0.57);
} */
/* .pcms .imgbox .imgright {
  width: 745px;
  height: 560px;
  background: red;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
} */
/* .pcms .imgbox .box {
  width: 366px;
  height: 272px;
  background:red;
  margin-top: 5px;
  margin-left: 5px;
} */

.btn {
    background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/icon/btn.png) no-repeat;
    width: 227px;
    height: 46px;
    line-height: 46px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #54413c;
    text-align: center;
    margin: 36px auto 0px;
    cursor: pointer;
}
</style>