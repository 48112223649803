<template>
  <div>
    <Swiper></Swiper>
    <!-- <Dqmj></Dqmj> -->
    <Tjgl></Tjgl>
    <Rmxl></Rmxl>
    <Bottombanner></Bottombanner>
  </div>
</template>

<script>
import Swiper from "../swiper/swiper";
import Dqmj from "../dqmj/dqmi";
import Tjgl from "../tjgl/tjgl";
import Rmxl from "../rmxl/rmxl";
import Bottombanner from "../bottombanner/bottombanner";
export default {
  name: "",
  components: {
    Swiper,
    Dqmj,
    Tjgl,
    Rmxl,
    Bottombanner
  },
};
</script>

<style lang="less" scoped>
</style>
