<template>
  <div>
    <div
      class="rmxldetail"
      v-for="(item, index) in remenxianlu"
      :key="index"
      @click="remenline(item.id)"
    >
      <img class="img" v-lazy="baseUrl + item.imgUrl" alt="" />
      <div class="content">
        <div class="title">
          <div class="t1">{{ item.title }}</div>
        </div>
        <div class="line"></div>
        <div class="xxbox">
          <div class="xx">
            <img
              src="http://qiniu.image.citgroup.cn/zhangjiakou/icon/xx.png"
              alt=""
            />
            <img
              src="http://qiniu.image.citgroup.cn/zhangjiakou/icon/xx.png"
              alt=""
            />
            <img
              src="http://qiniu.image.citgroup.cn/zhangjiakou/icon/xx.png"
              alt=""
            />
            <img
              src="http://qiniu.image.citgroup.cn/zhangjiakou/icon/xx.png"
              alt=""
            />
            <img
              src="http://qiniu.image.citgroup.cn/zhangjiakou/icon/xx.png"
              alt=""
            />
          </div>
        </div>
        <div class="pj">5.0分</div>
        <div class="t3">
          {{ item.briefInfo }}
        </div>
      </div>
      <div class="banner"></div>
    </div>
    <div class="blocklist">
      <el-pagination
        :page-size="size"
        layout="prev, pager, next"
        :total="totalSize"
        @current-change="currentChange()"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 0,
      remenxianlu: [],
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      currentPage: 1,
      totalSize:0,
      size: 5,
      language: this.$i18n.locale
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    remenline(id) {
      // this.$router.push({
      //   path: "/remenlinedetails",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/strategyApi/getDetail/'+id
        },
      });      
    },
    async home() {
      let result = await this.$http({
        // url: "/api/strategyApi?cxfl=8&classify=120&isRecommond=1&size=5",
        url: `/api/strategyApi?cxfl=8&classify=32&language=${this.language}&isRecommond=1&size=${this.size}&page=${this.currentPage}`,
        method: "get",
      });
      console.log(result);
      this.remenxianlu = result.data.data;
      this.totalSize = result.data.totalSize
    },
    currentChange(){
      this.home();
    },
  },
};
</script>

<style>
.rmxldetail {
  width: 100%;
  height: 165px;
  /* background: chartreuse; */
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 66px;
  margin-bottom: 158px;
}
.rmxldetail .img {
  width: 465px;
  height: 261px;
  background: cornflowerblue;
}
.rmxldetail .content {
  width: 678px;
  height: 165px;
  /* background: darkolivegreen; */
  position: relative;
  text-align: left;
  cursor: pointer;
}
.rmxldetail .content .title {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.rmxldetail .content .title .t1 {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #777777;
      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rmxldetail .content .line {
  width: 49px;
  height: 4px;
  background: #ce594d;
  border-radius: 2px;
  margin-top: 60px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.rmxldetail .content .xxbox {
  width: 150px;
  height: 20px;
  /* background: pink; */
  display: flex;
  justify-content: space-between;
  margin-top: 66px;
}
.rmxldetail .content .xxbox .xx img {
  width: 21px;
  height: 20px;
}
.rmxldetail .content .pj {
  width: 60px;
  height: 21px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #787878;
  margin-top: -24px;
  margin-left: 114px;
}
.rmxldetail .content .t3 {
  width: 100%;
  /* height: 64px; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #777777;
  margin-top: 38px;
      overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.rmxldetail .banner {
  width: 1200px;
  height: 1px;
  background: #dddddd;
  /* background: red; */
  position: absolute;
  bottom: -125px;
}

.blocklist{
  margin-top: 100px;
  text-align: center;
}
.el-pagination {
    width: 1200px;
    height: 40px;
}
.el-pagination .btn-prev {
  height: 40px;
}
.el-pagination .btn-prev i{
  font-size: 20px;
}
.el-pagination .btn-prev:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pagination .btn-next {
  height: 40px;
}
.el-pagination .btn-next i{
  font-size: 20px;
}
.el-pagination .btn-next:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pager {
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.el-pager .number{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #f8f8f8;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
    color: #000000 !important;
    font-size: 20px;
    font-weight: 300;
    margin-left: 23px;
}
.el-pager .number:hover{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
.el-pager .active{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
</style>