<template>
  <!-- 大好河山 -->
  <div class="yjgl1">
    <div class="h2">{{ $t('content.travelGuide') }}</div>
    <ul class="nav">
      <li v-for="(item,index) in tabs" v-bind:class="{isActive: currentTabId == item.id}" :key="index" @click="getStrategyApi(item.classify, item.id, true)">{{ item.title }}</li>
    </ul>
    <!-- 游记攻略详情 -->
    <!-- <Yjgldetail></Yjgldetail> -->
    <div>
      <div class="yjgldetail"  v-for="(item, index) in yjgldetailArr" :key="index" @click="yjdetail(item.id)">
        <img class="img" v-lazy="baseUrl + item.imgUrl" alt="" />
        <div class="content">
          <div class="title">
            <div class="t1">{{item.title}}</div>
            
          </div>
          <div class="line"></div>
          <div class="t3">
            {{item.briefInfo}}
          </div>
        </div>
        <div class="banner"></div>
      </div>
    
    </div>
    <div class="block">
      <el-pagination
        :page-size="5"
        layout="prev, pager, next"
        :total="totalSize"
        @current-change="currentChange()"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import Yjgldetail from '../yjgldetail/yjgldetail'
export default {
  components: {
    // Yjgldetail
  },
  data() {
    return {
        tabs: [{title: "全部", classify: 888, id:1},{title: "特色线路", classify: 120, id:2},{title: "游记", classify:35, id:3}],
        currentTabId:1,
        page:1,
        size:5,
        classify: 32,
        yjgldetailArr: [],
        currentPage: 1,
        totalSize:0,
        baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
        language: this.$i18n.locale
    };
  },
  // mounted() {
  //   this.home();
  // },
  methods: {
    async getStrategyApi(classify, tabId, reset){
      if (reset) {
        this.classify = classify;
        this.currentTabId = tabId;
        this.page = 0;
        this.size = 5;
        this.currentPage = 1;
      }
      let result = await this.$http({
        url: '/api/strategyApi?cxfl=8&language=' + this.language + '&size='+ this.size +'&classify='+ this.classify + '&page='+ this.currentPage,
        method: "GET",
      })
      if (result && result.status == 200) {
        this.totalSize = result.data.totalSize;
        this.yjgldetailArr = result.data.data;
      } else {
        this.$message.error('网络请求出错');
      }
    },
    yjdetail(id) {
      // this.$router.push({
      //   path: "/yjdetail",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/strategyApi/getDetail/'+id
        },
      });
    },
    currentChange(){
      this.getStrategyApi(this.classify);
    },
    // async getStrategyApiClassify(){

    //   let result = await this.$http({
    //     url: '/api/strategyApi',
    //     method: "GET",
    //   })
    //   if (result && result.status == 200) {
    //       console.log(result);
    //   } else {
    //     this.$message.error('网络请求出错');
    //   }
    // },
  },
  created() {
      this.getStrategyApi(this.classify);
      // this.getStrategyApiClassify();
      console.log(this.$t('content.travelGuide'));
      if(this.$t('content.travelGuide') == '游记攻略'){
        this.tabs = [{title: "全部", classify: 888, id:1},{title: "特色线路", classify: 120, id:2},{title: "游记", classify:35, id:3}]
        this.tabs = [{title: "自驾游", classify: 32, id:1},{title: "自助游", classify: 33, id:2},{title: "团队游", classify:34, id:3},{title: "游记", classify:35, id:4}]
      }else if(this.$t('content.travelGuide') == 'Travel guide')
      {
        // this.tabs = [{title: "all", classify: 888, id:1},{title: "Characteristic line", classify: 120, id:2},{title: "travel notes", classify:35, id:3}]
        this.tabs = [{title: "self-driving tour", classify: 32, id:1},{title: "DIY tour", classify: 33, id:2},{title: "team tour", classify:34, id:3},{title: "travel notes", classify:35, id:4}]
      };
  },
};
</script>

<style>
.block {
  text-align: center;
}
.isActive {
		color: #e60012;
	}
.yjgl1 {
  width: 1200px;
  /* height: 1350px; */
  /* background-color: sienna; */
  margin: 0px auto 100px;
}
.yjgl1 .h2 {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin: 105px auto 31px;
}
.yjgl1 .nav {
  width: 650px;
  height: 42px;
  /* background: teal; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;
}
.yjgl1 .nav li {
  width: 142px;
  height: 42px;
  background: #ffeae8;
  opacity: 0.73;
  border-radius: 21px;
  text-align: center;
  line-height: 42px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  cursor: pointer;
}
.yjgl1 .nav li:hover {
  color: #d1524e;
}
.yjgldetail {
  width: 100%;
  height: 165px;
  /* background: chartreuse; */
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 24px;
  margin-bottom: 51px;
  cursor: pointer;
}
.yjgldetail .img {
  width: 293px;
  height: 165px;
  background: cornflowerblue;
}
.yjgldetail .content {
  width: 869px;
  height: 165px;
  /* background: darkolivegreen; */
  position: relative;
}
.yjgldetail .content .title {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.yjgldetail .content .title .t1 {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #777777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.yjgldetail .content .line {
  width: 49px;
  height: 4px;
  background: #ce594d;
  border-radius: 2px;
  margin-top: 60px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.yjgldetail .content .t3 {
  width: 869px;
  /* height: 64px; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #777777;
  margin-top: 38px;
   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.yjgldetail .banner {
  width: 1200px;
  height: 1px;
  background: #dddddd;
  /* background: red; */
  position: absolute;
  bottom: -24px;
}
.el-pagination {
    width: 1200px;
    height: 40px;
}
.el-pagination .btn-prev {
  height: 40px;
}
.el-pagination .btn-prev i{
  font-size: 20px;
}
.el-pagination .btn-prev:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pagination .btn-next {
  height: 40px;
}
.el-pagination .btn-next i{
  font-size: 20px;
}
.el-pagination .btn-next:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pager {
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.el-pager .number{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #f8f8f8;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
    color: #000000 !important;
    font-size: 20px;
    font-weight: 300;
    margin-left: 23px;
}
.el-pager .number:hover{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
.el-pager .active{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
</style>