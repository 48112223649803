<template>
  <div class="imgtwo" v-lazy:background-image="{src: 'http://qiniu.image.citgroup.cn/zhangjiakou/icon/bjt.png'}">
    <div class="bottom-title">{{$t('bottom.yqlj')}}</div>
    <div class="bottom-nav">
      <div class="boxbottom" @click="num=0" :class="{active:num==0,boxbottombg:num==0}">
        <div class="t1">{{$t('bottom.lyjgw')}}</div>
      </div>
      <span class="longstring">|</span>
      <div class="boxbottom" @click="num=1" :class="{active:num==1,boxbottombg:num==1}">
        <div class="t1">{{$t('bottom.jqwz')}}</div>
      </div>
      <span class="longstring">|</span>
      <div class="boxbottom" @click="num=2" :class="{active:num==2,boxbottombg:num==2}">
        <div class="t1">{{$t('bottom.hzwz')}}</div>
      </div>
      <span class="longstring">|</span>
      <div class="boxbottom" @click="num=3" :class="{active:num==3,boxbottombg:num==3}">
        <div class="t1">{{$t('bottom.zwwz')}}</div>
      </div>
    </div>
    <div class="smallnav" v-show="num==0">
      <div class="box1">
        <div class="t2"><a class="box1t2a" href="http://wgl.my.gov.cn/" target="_blank">{{$t('bottom.zjkCBATB')}}</a></div>
      </div>
      <div class="box1">
        <div class="t2"><a class="box1t2a" href="http://wlt.sc.gov.cn/" target="_blank">{{$t('bottom.ministryOfCultureAndTourism')}}</a></div>
      </div>
    </div>
    <div class="smallnav" v-show="num==1"></div>
    <div class="smallnav" v-show="num==2">
      <div class="box1">
        <div class="t2"><a class="box1t2a" href="http://www.citgroup.cn/" target="_blank">{{$t('bottom.citgroup')}}</a></div>
      </div>
    </div>
    <div class="smallnav" v-show="num==3">
      <div class="box1">
        <div class="t2"><a class="box1t2a" href="http://www.my.gov.cn/" target="_blank">{{$t('bottom.zjkGovernment')}}</a></div>
      </div>
    </div>
    <div class="line"></div>
    <div class="record">
      Copyright © 2020-2023 {{$t('bottom.zjkCBATB')}}, All Rights Reserved.
      <br>
      <a href="https://beian.miit.gov.cn/" target="_blank">{{$t('bottom.bottomContentTwo')}}</a>
      <br>
      {{ $t('bottom.technicalSupport') }} <a href="http://www.citgroup.cn/" title="中智游（北京）科技有限公司" target="_blank">{{$t('bottom.bottomContentThree')}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      yqlj:'友情链接',
      lyjgw:'旅游局网站',
      jqwz:'景区网站',
      hzwz:'合作网站',
      zwwz:'政务网站',
      gjlyj:'国家旅游局',
      zjklyzxw:'张家口旅游资讯网',
      zjklyj:'张家口旅游局',
      zjklyw:'张家口旅游网',
      zjkysp:'张家口野山坡',
      zjkly:'张家口旅游',
      xc:'携程',
      fz:'飞猪',
      lmm:'驴妈妈',
      whlyb:'文化旅游部',
      zjk:'张家口',
      zjkzx:'张家口在线',
      num: "0"
    }
  }
};
</script>

<style>
.imgtwo {
  background: no-repeat center center;
  width: 100%;
  height: 573px;
  margin: 0px auto;
}
.bottom-title {
  /* width: 95px; */
  height: 24px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 100px;
}
.bottom-nav {
  display: flex;
  width: 850px;
  height: 38px;
  justify-content: space-around;
  margin: 104px auto 42px;
}
.bottom-nav .boxbottom {
  /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/icon1.png); */
  width: 163px;
  height: 38px;
}
.boxbottombg {
  background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/icon1.png);
}
.bottom-nav .boxbottom .t1 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin: 8px auto;
  cursor: pointer;
}
.smallnav {
  width: 850px;
  height: 108px;
  /* background: pink; */
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
}
.smallnav .box1 {
  width: 170px;
  height: 86px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}
.line {
  width: 1384px;
  height: 1px;
  background: #dddddd;
  margin: 43px auto;
}
.record{
    width: 543px;
    height: 37px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
    margin: 32px auto;
    text-align: center;
}
.box1t2a{
  text-decoration: none;
  color: #fff;
}
/* .box1t2a:hover{
  text-decoration: none;
  color: #fff;
  background: #0076ba;
} */
.record a {
  text-decoration: none;
  color: #fff;
}
.longstring {
  color: #ffff;
  line-height: 38px;
}
</style>