<template>
  <div class="ddmj-adress">
    <div class="ddmj-banxin1">
      <div class="h2" >{{ $t('content.inTheBeauty') }}</div>
      <div class="navname">
        <div class="ddmj-box" v-lazy:background-image="{src: 'https://qiniu.image.citgroup.cn/sichuan/qiqushan/cms/spot.png'}"></div>
        <div class="title">
          {{ zjkjs.briefInfo }}
        </div>
        <div class="more" @click="liaojiegengduo">{{ $t('content.knowMoreAbout') }}</div>
      </div>
      <div class="swiper-container jjmc">
        <swiper :options="swiperOption">
          <swiper-slide class="boxcicle" v-for="(item, index) in bfsj" :key="index" :data-id="item.id">
            <div class="cicle" 
            :style="{ backgroundImage: 'url(' + baseUrl + item.imgUrl + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover', }"
            >
            </div>
            <div class="jqname">{{item.source }}</div>
            <div class="content">{{ item.title }}</div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "../../../node_modules/swiper";
var vm='';
export default {
  name: "swiper-example-loop-group",
  title: "Loop mode with multiple slides per group",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      dangjimeijing: "当季美景",
      ljgd: "了解更多",
      bfsj: [],
      zjkjs: {},
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on:{
          click: function(s,e){
            // console.log(e.path[1].dataset.id);
            vm.ceshi(e.path[1].dataset.id)
          },
        },
      },
      language: this.$i18n.locale
    };
  },
  mounted() {
    vm=this;
    this.home();
    this.binfensiji();
  },
  methods: {
    ceshi(id){
      console.log(id)
      // this.$router.push({
      //   path: "/ceshidatails",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/travelStoreApi/getDetail/'+id
        },
      });
    },
    liaojiegengduo() {
      this.$router.push("/liaojiegengduodetails").catch(() => {});
    },
    async home() {
      let id;
      if(this.language == 206) {
        id = 1;
      } else if (this.language == 205) {
        id = 3;
      }
      let result = await this.$http({
        url: `/api/scenicApi/getScenicById/${id}`,
        method: "get",
      });
      console.log(result);
      this.zjkjs = result.data.data;
    },
    async binfensiji() {
      let result = await this.$http({
        url: `/api/travelStoreApi?cxfl=8&classify=42&language=${this.language}&page=1&size=3`,
        method: "get",
      });
      console.log(result);
      this.bfsj = result.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
/* 地区美景 */
.ddmj-adress {
  width: 100%;
  height: 798px;
  z-index: 1;
  position: absolute;
}
.ddmj-adress .ddmj-banxin1 {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  /* background-color: pink; */
  text-align: center;
}
.ddmj-adress .ddmj-banxin1 .h2 {
  height: 84px;
  line-height: 103px;
  /* background-color: green; */
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.ddmj-adress .ddmj-banxin1 .navname {
  width: 1200px;
  height: 280px;
  background: #2a7ac0;
  opacity: 0.9;
  margin-top: 204px;
  position: relative;
}
.ddmj-adress .ddmj-banxin1 .navname .ddmj-box {
  width: 294px;
  height: 350px;
  // background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/cms/upload/img/scenic/scenic_1596527560667.jpg);
  position: absolute;
  top: -130px;
  left: 42px;
  background-size: 100% 100%;
}
.ddmj-adress .ddmj-banxin1 .navname .title {
  width: 775px;
  height: 101px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  left: 387px;
  top: 34px;
  text-align: left;
}
.ddmj-adress .ddmj-banxin1 .navname .more {
  width: 90px;
  height: 25px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 32px;
  left: 391px;
  font-size: 16px;
  font-family: inpinheiti;
  font-weight: bold;
  color: #ffffff;
  line-height: 27px;
  cursor: pointer;
}
.jjmc {
  width: 1200px;
  height: 340px;
  background: #ffffff;
  box-shadow: 1px 3px 13px 0px rgba(8, 1, 3, 0.16);
}

.jjmc .w-box {
  width: 906px;
  height: 268px;
  /* background: indianred; */
  margin-left: 156px;
  display: flex;
  justify-content: space-between;
}
.jjmc .boxcicle{
  cursor: pointer;
}
.jjmc .boxcicle .cicle {
  width: 167px;
  height: 167px;
  border-radius: 50%;
  // background: black;
  margin-left: 105px;
  margin-top: 15px;
}
.jjmc .w-box .boxcicle .jqname {
  width: 74px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 27px;
  margin: 20px auto;
}
.jjmc .w-box .boxcicle .content {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 27px;
  text-align: center;
}
</style>