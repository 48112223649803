<template>
  <div class="food-banxin">
    <div class="food">{{ $t('content.specialty') }}</div>
    <div class="fenlei">
      <div class="flbox" v-for="(item, index) in meishifenlei" :key="index" @click="pincmeishi(item.id)">
        <!-- <div class="h3">分类</div> -->
        <div class="img">
          <img v-lazy="baseUrl + item.imgUrl" alt="" class="imglist">
          <div class="title">{{item.title}}</div>
        </div>
      </div>
      <!-- <div class="flbox">
        <div class="h3">分类</div>
        <div class="img"></div>
      </div>
      <div class="flbox">
        <div class="h3">分类</div>
        <div class="img"></div>
      </div>
      <div class="flbox">
        <div class="h3">分类</div>
        <div class="img"></div>
      </div> -->
    </div>
    <div class="fenlei1">
      <div class="img" v-for="(item, index) in meishifenleiTwo" :key="index" @click="pincmeishi(item.id)">
        <img v-lazy="baseUrl + item.imgUrl" alt="" class="imglist">
        <div class="title">{{item.title}}</div>
      </div>
      <!-- <div class="img"></div>
      <div class="img"></div> -->
    </div>
    <div class="btn" @click="specialty">{{ $t('content.browseMore') }}</div>
  </div>
  
</template>

<script>
export default {
  data() {
    return {
      meishifenlei: [],
      meishifenleiTwo: [],
      liulangengduo: "浏览更多",
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      language: this.$i18n.locale
    }
  },
  mounted() {
    this.home();
    this.homeTwo();
  },
  methods: {
    pincmeishi(id) {
      // this.$router.push({
      //   path: "/publicdqmjdetails",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/featureApi/getDetail/'+id
        },
      });
    },
    async home() {
      let result = await this.$http({
        //url: "/api/travelStoreApi?cxfl=8&classify=39&size=4",
        url: `/api/featureApi?cxfl=8&classify=46&language=${this.language}&page=1&size=4`,
        method: "get",
      });
      this.meishifenlei = result.data.data;
    }, 
    async homeTwo() {
      let result = await this.$http({
        url: `/api/featureApi?cxfl=8&classify=46&language=${this.language}&page=2&size=3`,
        method: "get",
      });
      this.meishifenleiTwo = result.data.data;
    }, 
    specialty() {
      // this.$router.push('/public').catch(()=>{})
      this.$router.push({
        path: '/public',
        query: {
          api: '/api/featureApi'
        }
      })
    }   
  }
};
</script>

<style>
.food-banxin {
  width: 1200px;
  height: 860px;
  /* background: olive; */
  margin: 90px auto;
}
.food-banxin .food {
  width: 213px;
  height: 48px;
  font-size: 51px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 48px;
  margin: 0px auto;
}
.food-banxin .fenlei {
  width: 100%;
  height: 380px;
  /* background: palegreen; */
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
}
.food-banxin .fenlei .flbox {
  width: 288px;
  height: 380px;
  /* background: plum; */
}
.food-banxin .fenlei .flbox .h3 {
  width: 66px;
  height: 31px;
  font-size: 33px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #e60013;
  margin: 0px auto 61px;
}
.food-banxin .fenlei .flbox .img {
  width: 288px;
  height: 288px;
  background: tan;
  position: relative;
}
.food-banxin .fenlei .flbox .img .title {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 22px;
}
.food-banxin .fenlei1 {
  width: 1200px;
  height: 288px;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}
.food-banxin .fenlei1 .img {
  width: 385px;
  height: 288px;
  background: teal;
  position: relative;
}
.food-banxin .fenlei1 .img .title {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 22px;
}
.imglist {
  width: 100%;
  height: 288px;
}
.btn {
    background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/icon/btn.png) no-repeat;
    width: 227px;
    height: 46px;
    line-height: 46px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #54413c;
    text-align: center;
    margin: 36px auto 0px;
    cursor: pointer;
}
</style>