<template>
  <div class="tjgl" v-lazy:background-image="{src: 'http://qiniu.image.citgroup.cn/zhangjiakou/icon/bjt2.png'}">
    <div class="title">{{ $t('content.theRecommendedStrategy') }}</div>
    <div class="t1">{{ $t('content.theRecommendedStrategyContent') }}</div>
    <div class="img">
      <div
        class="box1"
        v-for="(item, index) in tuijiangl"
        :key="index"
        :style="{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }"
        v-lazy:background-image="{src: baseUrl + item.imgUrl}"
        @click="tjgl(item.id)"
      ></div>
      <!-- <div class="box1"></div> -->
    </div>
    <!-- <div class="btn" @click="tuijiangongluemore"></div> -->
    <div class="btnnew" @click="tuijiangongluemore">{{ $t('content.browseMore') }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tuijiangognlue: "推荐攻略",
      content: "经过365天的实际体验为您精挑细选出最优美尽兴的路线规划",
      tuijiangl: [],
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      language: this.$i18n.locale
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    tjgl(id) {
      // this.$router.push({
      //   path: "/publicdqmjdetails",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          // detailApi:'/api/travelStoreApi/getDetail/'+id
          detailApi:'/api/strategyApi/getDetail/'+id
        },
      });
    },
    tuijiangongluemore() {
      this.$router.push('/tuijiangongluemore').catch(()=>{})
    },
    async home() {
      let result = await this.$http({
        // url: "/api/travelStoreApi?cxfl=8&classify=41&size=2",
        url: `/api/strategyApi?cxfl=8&classify=35&page=1&size=2&language=${this.language}`,
        method: "get",
      });
      console.log(result);
      this.tuijiangl = result.data.data;
    },
  },
};
</script>

<style>
.tjgl {
  /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/bjt2.png); */
  width: 100%;
  height: 596px;
  margin: 88px auto 0px;
}
.tjgl .title {
  font-size: 28px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  line-height: 108px;
  text-align: center;
}
.tjgl .t1 {
  font-size: 14px;
  font-family: 35;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0px;
  text-align: center;
}
.tjgl .img {
  width: 1321px;
  height: 331px;
  /* background: greenyellow; */
  display: flex;
  justify-content: space-between;
  margin: 54px auto 24px;
}
.tjgl .img .box1 {
  width: 644px;
  height: 331px;
  background: #666666;
}
.tjgl .btn {
  height: 50px;
  width: 234px;
  background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/icon3.png)
    no-repeat;
  margin: -25px auto;
  cursor: pointer;
}
.btnnew{
  background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/icon/btn.png)
    no-repeat;
  width: 227px;
  height: 46px;
  line-height: 46px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 36px auto 0px;
  cursor: pointer;
}
</style>