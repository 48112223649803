<template>
  <div>
    <Swiper></Swiper>
    <!-- <Chinazjk></Chinazjk> -->
    <Dhhs></Dhhs>
    <Fjrh></Fjrh>
    <Yjgl></Yjgl>
    <Bottombanner></Bottombanner>
  </div>
</template>

<script>
import Swiper from '../swiper/swiper';
import Chinazjk from "../chinazjk/chinazjk";
import Dhhs from "../dhhs/dhhs";
import Fjrh from "../fjrh/fjrh";
import Yjgl from "../yjgl/yjgl";
import Bottombanner from "../bottombanner/bottombanner";
export default {
  name: "",
  components: {
    Swiper,
    Chinazjk,
    Dhhs,
    Fjrh,
    Yjgl,
    Bottombanner
  },
  
};
</script>

<style lang="less" scoped>
.el-carousel__item h3 {   
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
