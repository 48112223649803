<template>
  <!-- 大好河山 -->
  <div class="fjrh">
    <div class="h2">{{ $t('content.picturesque') }}</div>
    <div class="listone">
      <div class="title" 
        v-for="(item, index) in fjrhArr" 
        :key="index" 
        :style="{backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover',}" 
        v-lazy:background-image="{src: baseUrl + item.imgUrl}"
        @click="fjrhdetail(item.id)"
      >
        <div class="info">
          <div class="name">{{ item.address }}</div>
          <div class="briefInfo">{{ item.briefInfo }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      fjrh: "风景如画",
      fjrhArr: [],

      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      language: this.$i18n.locale
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    fjrhdetail(id) {
      // this.$router.push("/fjrhdetails").catch(() => {});
      // this.$router.push({
      //   path: '/fjrhdetails',
      //   query: {
      //     id
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/travelStoreApi/getDetail/'+id
        },
      });
    },
    async home() {
      let resoult = await this.$http({
        url: `/api/travelStoreApi?cxfl=8&classify=42&size=8&language=${this.language}`,
        method: "get",
      });
      console.log(resoult);
      this.fjrhArr = resoult.data.data;
    },
  },
};
</script>

<style>
.fjrh {
  width: 100%;
  height: 816px;
  /* background: red; */
}
.fjrh .h2 {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-bottom: 74px;
}
.fjrh .listone {
  height: 676px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.fjrh .listone .title {
  width: 25%;
  height: 338px;
  /* display: block; */
  cursor: pointer;
  position: relative;
}
.fjrh .listone .title .name {
  position: absolute;
  color: white;
  bottom: 20px;
  left: 20px;
}
.fjrh .listone .title .info {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}
.fjrh .listone .title .briefInfo {
  color: #fff;
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.5s;
}

.fjrh .listone .title:hover .briefInfo {
  opacity: 1;
}
.fjrh .listone .listone-img {
  display: block;
  width: 25%;
  height: 338px;
}
</style>