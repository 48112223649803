<template>
  <div class="djmj-banxin">
    <div class="title">{{ $t('content.popularDestination') }}</div>
    <div class="content" >
      <div
        class="img"
        v-for="(item, index) in remnejingdian"
        :key="index"
        :style="{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }"
        v-lazy:background-image="{src: baseUrl + item.imgUrl}"
      >
        <div class="t1">
          <div class="name">{{ item.title }}</div>
          <div class="detail">
            {{ item.briefInfo }}
          </div>
          <div class="more" @click="remenjingdian(item.id)">{{ $t('content.viewDetails') }}>></div>
        </div>
      </div>
    </div>
    <!-- 浏览更多 -->
    <div class="btnlocation" @click="Liulangengduo">{{ $t('content.browseMore') }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "热门景点",
      remnejingdian: [],
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      language: this.$i18n.locale
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    remenjingdian(id) {
      // this.$router.push({
      //   path: "/publicdqmjdetails",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/travelStoreApi/getDetail/'+id
        },
      });
    },
    // tuijiangongluemore() {
    //   this.$router.push('/tuijiangongluemore').catch(()=>{})
    // },
    async home() {
      let result = await this.$http({
        url: `/api/travelStoreApi?cxfl=8&classify=42&language=${this.language}&page=2&size=3`,
        method: "get",
      });
      console.log(result);
      this.remnejingdian = result.data.data;
    },
    Liulangengduo() {
      this.$router.push("/dqmjmore").catch(() => {});
    },
  },
};
</script>

<style>
.djmj-banxin {
  width: 1200px;
  height: 520px;
  margin: 909px auto 0px;
  /* background: red; */
}
.djmj-banxin .title {
  /* width: 210px; */
  height: 48px;
  font-size: 51px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 51px;
  margin: 0px auto 69px;
  text-align: center;
  padding-top: 30px;
}
.djmj-banxin .content {
  width: 1200px;
  height: 401px;
  background: grey;
  display: flex;
  justify-content: space-between;
}
.djmj-banxin .content .img {
  width: 380px;
  height: 280px;
  background: #8ac998;
}
.djmj-banxin .content .img .t1 {
  width: 305px;
  height: 194px;
  background: #ffffff;
  box-shadow: 1px 3px 18px 0px rgba(8, 1, 3, 0.14);
  margin: 207px auto 102px;
}
.djmj-banxin .content .img .t1 .name {
  width: 144px;
  /* height: 17px; */
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ce5a4e;
  /* line-height: 20px; */
  margin: 0px auto 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.djmj-banxin .content .img .t1 .detail {
  width: 271px;
  /* height: 73px; */
  /* background: red; */
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin: 0px auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;/*超出3行部分显示省略号，去掉该属性 显示全部*/
        -webkit-box-orient: vertical;
}
.djmj-banxin .content .img .t1 .more {
  /* width: 90px; */
  height: 21px;
  background: #ce5a4e;
  line-height: 21px;
  text-align: center;
  margin-left: 206px;
  margin-top: 54px;
  font-size: 14px;
  font-family: inpinheiti;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  cursor: pointer;
}
/* 浏览更多 */
.btnlocation {
  background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/icon/btn.png)
    no-repeat;
  width: 227px;
  height: 46px;
  line-height: 46px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #54413c;
  text-align: center;
  margin: 36px auto 0px;
  cursor: pointer;
}
</style>