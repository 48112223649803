<template>
  <div>
    <Swiper></Swiper>
    <div class="publicdetailscontactus">
      <div class="boxcontactus">
        <div v-html="datailArr.content"></div>
      </div>
    </div>
    <Bottombanner></Bottombanner>
  </div>
</template>

<script>
import Swiper from "./swiper/swiper";
import Bottombanner from "./bottombanner/bottombanner";
export default {
  name: "ContactUs",
  components: {
    Swiper,
    Bottombanner,
  },
  data() {
    return {
      datailArr: [],
      language: this.$i18n.locale
    };
  },
  methods: {
    async home() {
      let id;
      if(this.language == 206) {
        id = 1;
      } else if(this.language == 205) {
        id = 3;
      }
      let result = await this.$http({
        url: `/api/scenicApi/getScenicById/${id}`,
        method: "get",
      });
      this.datailArr = result.data.data;
      console.log(result.data.data);
    },
  },
  mounted() {
    this.home();
  },
};
</script>

<style scoped>
.publicdetailscontactus {
  width: 1200px;
  /* height: 100vh; */
  /* background: #cecece; */
  margin: 0 auto;
  overflow: hidden;
}

.publicdetailscontactus .boxcontactus {
  width: 100%;
  /* height: 1500px; */
  /* background: green; */
  margin-top: 88px;
}
</style>