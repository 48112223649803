<template>
  <div class="mrtj-banxin">
    <div class="icon"></div>
    <div class="mrtj">{{ $t('content.recommendedDailyNews') }}
      <div class="moremrtj" @click="chakangd">{{ $t('content.viewMore') }}>></div>
    </div>
    <div class="content">
      <div
        class="detail"
        v-for="(item, index) in meirituijianxw"
        :key="index"
        @click="jinri(item.id)"
      >
        <div
          class="img"
          :style="{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }"
          v-lazy:background-image="{src: baseUrl + item.imgUrl}"
        >
        </div>
        <div class="t1">{{ item.title }}</div>
        <div class="t2">
          {{ item.briefInfo }}
        </div>
        <!-- <div class="icon"></div>
        <div class="people">1121分享</div>
        <div class="icon1"></div>
        <div class="people1">1121点赞</div>
        <div class="icon2"></div>
        <div class="people2">1121评论</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      meirituijianxinwen: "每日推荐新闻",
      meirituijianxw: [],
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      language: this.$i18n.locale
    };
  },
  mounted() {
    this.home();
  },
  methods: {
    jinri(id) {
      // this.$router.push({
      //   path: "/xinwenpaihangmore",
      //   query: {
      //     id,
      //   },
      // });
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:'/api/noticeApi/getDetail/'+id
        },
      });
    },
    async home() {
      let result = await this.$http({
        url: `/api/noticeApi?cxfl=8&classify=36&language=${this.language}&size=3&page=1`,
        method: "get",
      });
      console.log(result);
      this.meirituijianxw = result.data.data;
    },
    chakangd() {
      this.$router.push("/chakangd").catch(() => {});
    },
  },
};
</script>

<style>
.mrtj-banxin {
  width: 1200px;
  /* height: 500px; */
  /* background: chocolate; */
  margin: 100px auto 0px;
  position: relative;
}
.mrtj-banxin .icon {
  width: 9px;
  height: 62px;
  background: #fe6060;
  position: absolute;
  left: 8px;
  top: 0px;
}
.mrtj-banxin .mrtj {
  /* width: 240px; */
  height: 38px;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 56px;
  margin-left: 40px;
}
.mrtj-banxin .content {
  width: 100%;
  height: 300px;
  /* background: darkcyan; */
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.mrtj-banxin .content .detail {
  display: flex;
  justify-content: space-around;
  width: 389px;
  height: 300px;
  /* background: #ffac38; */
  position: relative;
  /* margin-right: 10px; */
  cursor: pointer;
}
.mrtj-banxin .content .detail .img {
  width: 389px;
  height: 211px;
  background: darkgray;
}
.mrtj-banxin .content .detail .t1 {
  width: 390px;
  /* height: 290px; */
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  position: absolute;
  left: 0px;
  top: 213px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mrtj-banxin .content .detail .t2 {
  width: 309px;
  height: 37px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  position: absolute;
  left: 0px;
  top: 264px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.mrtj-banxin .content .detail .icon {
  background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/fenxiang.png)
    no-repeat;
  width: 15px;
  height: 14px;
  /* background: red; */
  position: absolute;
  left: 0px;
  top: 327px;
}
.mrtj-banxin .content .detail .people {
  width: 77px;
  height: 14px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #343434;
  position: absolute;
  left: 21px;
  bottom: 12px;
}
.mrtj-banxin .content .detail .icon1 {
  background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/xin.png)
    no-repeat;
  width: 18px;
  height: 16px;
  /* background: red; */
  position: absolute;
  left: 142px;
  top: 327px;
}
.mrtj-banxin .content .detail .people1 {
  width: 77px;
  height: 14px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #343434;
  position: absolute;
  left: 168px;
  bottom: 12px;
}
.mrtj-banxin .content .detail .icon2 {
  background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/dianzan.png)
    no-repeat;
  width: 19px;
  height: 19px;
  /* background: red; */
  position: absolute;
  left: 300px;
  top: 327px;
}
.mrtj-banxin .content .detail .people2 {
  width: 77px;
  height: 14px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #343434;
  position: absolute;
  left: 323px;
  bottom: 11px;
}

.moremrtj {
  width: 94px;
  height: 14px;
  font-size: 17px;
  font-family: 58;
  font-weight: bold;
  color: #343434;
  /* line-height: 77px;
  margin-top: -58px;
  margin-left: 251px; */
  cursor: pointer;
  float: right;
}
</style>